<template>
    <seccion-datos
        :titulo="accesousuario"
        tipo="secondary"
        @cambios="guardar($data)" 
    >
        <form class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="usuario-acceso-cia">{{ $t('general.usuario') }}</label>
                    <input 
                        type="email" 
                        class="form-control form-control-sm" 
                        id="usuario-acceso-cia" 
                        placeholder="Usuario"
                        v-model="usuario_web"
                    >
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="clave-acceso-cia">{{ $t('general.contrasenia') }}</label>
                    <input 
                        type="password" 
                        class="form-control form-control-sm" 
                        id="clave-acceso-cia" 
                        placeholder="Contraseña"
                        v-model="clave_web"
                    >
                </div>
            </div>
        </form>
    </seccion-datos>
</template>
<script>
export default {
    props: {
        datos: {
            type: Object,
        }
    },
    inject: ['guardar'],
    data() {
        return {
            usuario_web: null,
            clave_web: null,
            accesousuario:'Acceso usuario',
        }
    },
    watch: {
        datos() {
            this.init();
        }
    },
    methods: {
        init() {
            if (this.datos) {
                for (let dato in this.$data) {
                    if (this.datos[dato] !== null && this.datos[dato] !== undefined) {
                        this[dato] = this.datos[dato];
                    }
                }
            }
            this.accesousuario = this.$t('general.accesousuario');
        },
    },
    created() {
        this.init();
    },
}
</script>